import React from 'react'
import { graphql } from 'gatsby'
import Article, { VARIANT_OPENER, VARIANT_TEXT_INTENDED } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const pageConfig = {
  title: `Über uns - ${config.siteTitle}`,
  slug: `ueber-uns`,
  path: `ueber-uns`
}

const Page = ({ data }) => {
  const postNode = {
    title: pageConfig.title,
  }

  const block1 = {
    leading: 5,
    laces: true,
    variant: VARIANT_OPENER,
    headline: `Über uns`,
    lead: `Vor über 100 Jahren gründete der Urgroßvater von Bernhard Ziegler eine Lackfabrikation in der Eisenbahnstraße in Gundelsheim. Der Großvater Eugen Ziegler führte dies fort und bot daneben auch Bodenbeläge, Farben und Tapeten an.`,
    image: {
      alt: 'Placeholder',
      fluid: data.opener.childImageSharp.fluid
    },
  }

  const block2 = {
    dropcap: true,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `
    <p>Als die Firma an Hermann Ziegler überging, haben er und seine Frau Sonja das Sortiment weiter ausgebaut und Gardinen und Sonnenschutz mit aufgenommen. Der Platz reichte bald nicht mehr aus, sodass sie in den 90ern Lagerräume zu Verkaufsräumen umbauen ließen.</p>
    <p>Der Sohn Bernhard Ziegler entschloss sich früh die Familientradition fortzuführen, machte eine Ausbildung zum Raumausstattergeselle und wurde anschließend Raumausstattermeister. Durch sein Fachwissen, das er sich in verschiedenen Betrieben im Inn- und Ausland angeeignet hatte, konnte das Angebot um den Bereich Polstern erweitert werden. Vor wenigen Jahren rundete Bernhard Ziegler das Angebot ab, indem er sich zum Restaurator im Raumausstatter-Handwerk ausbilden ließ.</p>
    <p>Heute gehört auch der Standort Ludwigsburg zum Familienbetrieb.</p>
    <p>Durch die Mitgliedschaft im SÜDBUND und bei Raum hoch drei ist es auch in Zukunft möglich neue Trends und klassisches Design zu einem günstigen Preis-Leistungs-Verhältnis anzubieten.</p>
    `,
  }
  
  return (
    <Layout>
      <Helmet>
        <title>{pageConfig.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={pageConfig.path} customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    opener: file(relativePath: { eq: "ueberuns-opener.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
